<template>
  <div class="service_btn">
    <slot>
      <i class="iconfont icon-wodekefu btn_server_icon" @click="toService"></i>
    </slot>
  </div>
</template>

<script>
export default {
  methods: {
    toService() {
      this.$store.dispatch("toService");
    },
  },
};
</script>

<style lang="less" scoped>
.service_btn {
  .iconfont {
    color: var(--gold);
    font-size: calc(34rem / 16);
  }
}
</style>