<template>
  <div id="user">
    <div class="userInfo_bar_wrap"
      :style="{ backgroundImage: `url('${require('@tt/assets/mine/info_wrap_bg.png')}')` }">
      <!-- 顶部 -->
      <div class="user_titleContent">
        <div class="user_contentLeft">
          <div class="contentLeft_img">
            <!-- <img class="user_img" src="../../assets/Img/myImg/MyOne.png" alt="" srcset=""
              v-if="!MemberInformation.userimg" />
            <img v-else :src="verConfig.url + MemberInformation.userimg" class="user_img" alt="" /> -->
            <Avatar :src="MemberInformation.userimg" :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
          </div>
          <div class="contentLeft_text">
            <div>
              {{
        MemberInformation.username
          ? MemberInformation.username
          : $t("newAdd.noLoginUserName")
      }}
            </div>
            <div>
              <span class="label">{{ "ID" }}:&nbsp;</span>
              <span class="value">{{ MemberInformation.id || "" }}</span>
            </div>
            <div class="contentLeft_Vip" @click="showSign">
              <span class="label">{{ $t("member.title.level") }}:&nbsp;</span>
              <!-- <span class="value">
                {{ MemberInformation.level ? MemberInformation.level + 1 : 1 }}
              </span> -->
              <span class="value">
                {{ MemberInformation.rank_title || "" }}
              </span>
            </div>
            <div class="contentLeft_Vip">
              <span class="label">{{ $t("mine.credit") }}:&nbsp;</span>
              <span class="value">
                {{ MemberInformation.credit || "" }}
              </span>
            </div>
          </div>
        </div>
        <ServiceBtn />
      </div>
      <!-- 中间上面 -->
      <div class="user_content" :style="{ backgroundImage: `url('${require('@tt/assets/mine/infoBG.png')}')` }">
        <div class="balance_bar">
          <div class="label">{{ $t("My.MemberInformation.balance") }}</div>
          <div class="value">{{ $money(MemberInformation.money || 0, false) }}</div>
        </div>
        <div class="trade_btns">
          <div class="recharge" @click="RechargeEvent">
            <img :src="require('@/assets/mine/recharge.png')" class="icon" alt="" />
            <div class="text">{{ $t("My.MemberInformation.Recharge") }}</div>
          </div>
          <div class="withdraw" @click="WithdrawEvent">
            <img :src="require('@/assets/mine/withdraw.png')" class="icon" alt="" />
            <div class="text">{{ $t("My.MemberInformation.Withdraw") }}</div>
          </div>
        </div>
      </div>
    </div>

    <CellGroup class="group" v-for="(g_item, g_index) in gridItemArr" :key="`group_${g_index}`">
      <Cell :to="item.page" is-link class="cell_item" v-for="(item, index) in g_item" @click="handleClick(item)"
        :key="index">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont" :class="item.icon"></i>
            <span class="label">{{ $t(item.label) }}</span>
          </div>
        </template>
      </Cell>
    </CellGroup>
    <div class="logout">
      <Btn block color="var(--theme)" @click="logoutEvent">
        {{ MemberInformation.token ? $t("My.logout") : $t("newAdd.Login") }}
      </Btn>
    </div>
    <van-action-sheet v-model="show" :actions="verConfig.lans || Lans" @close="onClose" @select="onSelect" />
    <!-- <Tabbar active="user" :language="language" /> -->
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Dialog,
  Toast,
  CellGroup,
  Button as Btn,
} from "vant";
import Tabbar from "@/components/Tabbar";
import { REQUEST_API } from "@/http/api"; // 引入封装的axios
import { GET_USER_INFP, editAvatar } from "@/api";
import { maskMiddle } from "@/utils/common"
// 设置vant的多语言
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
// import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhCN from "@/i18n/zh.js";
import thTH from "@/i18n/th.js";
import trTR from "@/i18n/tr.js";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { Lans } from "@/i18n";
import ServiceBtn from "@/components/serviceBtn";
import { Avatar } from "@tt/components";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Dialog.name]: Dialog,
    CellGroup,
    Cell,
    Tabbar,
    ServiceBtn,
    Btn,
    Avatar
  },
  data() {
    this.Lans = Lans;
    return {
      country: Cookies.get("language"),
      language: "",
      MemberInformation: {
        name: "",
        code: "",
        money: "0",
        url: "",
      },
      money: "",
      test: {
        personal: "Personal information",
        preOrde: "Pre-order record",
        account: "Account details",
        recharge: "Recharge record",
        withdrawal: "Withdrawal record",
        invite: "Invite friends",
        announcement: "Announcement",
        team: "Team report",
        language: "Language setting",
      },
      gridItemArr: [
        [
          {
            label: "mine.label.rechargeRecord",
            icon: "icon-chongzhidingdan",
            page: "/recharge-list",
          }],
        [{
          label: "page.withdrawRecord",
          icon: "icon-tixiandingdan",
          page: "/withdraw-list",
        }],
        [{
          label: "add.Personalcenteraccountchangerecord",
          icon: "icon-zhangdanjilu",
          page: "/AccountData",
        }],
        [{
          label: `my.betRecord`,
          icon: "icon-youxijilu",
          page: "/Cart",
        }],
        [{
          label: `my.wallet`,
          icon: "icon-qianbao2",
          page: "/walletList",
        }],
        [
          {
            label: `my.modifyPass`,
            icon: "icon-xiugaimima",
            page: "/Password",
          }],
        [{
          label: "My.personal",
          icon: "icon-gerenxinxi1",
          page: "/PersonalCenter",
        }],
        // [{
        //   label: "mine.serviceTools.certify",
        //   icon: "icon-a-Frame2003",
        //   page: "/Certification",
        // }],
        [{
          label: "agent.title.agent",
          icon: "icon-a-daili1",
          page: "/agent",
        }],
        [{
          label: "newAdd.inviteFriends",
          icon: "icon-yaoqinghaoyou",
          page: "/inviteFriends",
        }],
        // [{
        //   label: "My.language",
        //   icon: "icon-yuyanshezhi",
        //   callBack: () => {
        //     this.show = true;
        //   },
        // }]
        // [
        //   {
        //     label: "My.language",
        //     icon: "icon-yuyanshezhi",
        //     callBack: () => {
        //       this.show = true;
        //     },
        //   },
        //   // {
        //   //   label: "My.download",
        //   //   icon: "icon-xiazai1",
        //   //   callBack: () => {
        //   //     if (this.$cookie.get("userInfo")) {
        //   //       if (localStorage.getItem("android")) {
        //   //         location.href = localStorage.getItem("android");
        //   //       } else {
        //   //         location.href = "http://www.baidu.com";
        //   //       }
        //   //     } else {
        //   //       this.$toast(
        //   //         "You are not logged in. Please log in and try again!"
        //   //       );
        //   //     }
        //   //   },
        //   // },
        // ],
        // vip推广页面
        // {
        //   id: 1,
        //   t: `my.vip`,
        //   flag: true,
        //   icon: "icon-VIP1",
        //   page: "/levelUpgrade",
        // },
        // 手机验证
        // {
        //   id: 2,
        //   t: `my.verifyPhone`,
        //   flag: true,
        //   icon: "icon-shoujihaoma",
        //   page: "/PhoneVerify",
        // },
        // 充值
        // {
        //   id: 2,
        //   url: require("@/assets/Img/myImg/Recharge.png"),
        //   t: `newTab.money`,
        //   flag: true,
        //   callBack: () => {
        //     this.$router.push("recharge-list");
        //   },
        // },
        // 提现
        // {
        //   id: 3,
        //   url: require("@/assets/Img/myImg/withdrawal.png"),
        //   t: `My.withdrawal`,
        //   flag: true,
        //   // t: this.$t("My.AccountRecord"),
        //   callBack: () => {
        //     if (this.$cookie.get("userInfo")) {
        //       this.$router.push("withdraw-list"); // 账户资料跳转-小饶
        //     } else {
        //       this.$toast(
        //         "You are not logged in. Please log in and try again!"
        //       );
        //     }
        //   },
        // },
        // 个人中心
        // {
        //   id: 4,
        //   url: require("@/assets/Img/myImg/personal-info.png"),
        //   t: `My.personal`,
        //   flag: true,
        //   callBack: () => {
        //     if (this.$cookie.get("userInfo")) {
        //       this.$router.push("PersonalCenter"); // 个人中心跳转-小饶
        //     } else {
        //       this.$toast(
        //         "You are not logged in. Please log in and try again!"
        //       );
        //     }
        //   },
        // },
        // 客服
        // {
        //   id: 5,
        //   url: require("@/assets/Img/myImg/kefu.png"),
        //   t: `mine.servier`,
        //   flag: true,
        //   callBack: () => {
        //     if (localStorage.getItem("service_url")) {
        //       location.href = localStorage.getItem("service_url");
        //     } else {
        //       this.$toast("The call center is not online yet");
        //     }
        //   },
        // },
        // 下载
        // {
        //   id: 7,
        //   url: require("@/assets/Img/myImg/downT.png"),
        //   t: `My.download`,
        //   flag: this.$isDownloadFn,
        //   icon: "icon-xiazai-wenjianxiazai-08",
        //   callBack: () => {
        //     console.log(1);
        //     if (this.$cookie.get("userInfo")) {
        //       if (localStorage.getItem("android")) {
        //         location.href = localStorage.getItem("android");
        //       } else {
        //         location.href = "http://www.baidu.com";
        //       }
        //     } else {
        //       this.$toast(
        //         "You are not logged in. Please log in and try again!"
        //       );
        //     }
        //   },
        // },
        // 邀请
        // {
        //   id: 8,
        //   url: require("@/assets/Img/myImg/sharePink.png"),
        //   t: `My.invite`,
        //   icon: "icon-fenxiang",
        //   flag: true,
        //   callBack: () => {
        //     // this.$router.push('InviteFriends') // 邀请好友跳转-小饶
        //     this.$router.push("/inviteFriends");
        //   },
        // },
        // 公司介绍
        // {
        //   id: 8,
        //   url: require("@/assets/Img/myImg/introduce.png"),
        //   t: `user.tools.introduce`,
        //   flag: true,
        //   callBack: () => {
        //     // this.$router.push('InviteFriends') // 邀请好友跳转-小饶
        //     this.$router.push("introduce");
        //   },
        // },
        // crypto
        // {
        //   id: 9,
        //   url: require("@/assets/Img/myImg/crypto.png"),
        //   t: `user.tools.crypto`,
        //   flag: true,
        //   callBack: () => {
        //     // this.$router.push('InviteFriends') // 邀请好友跳转-小饶
        //     this.$router.push("crypto");
        //   },
        // },
        // 币安
        // {
        //   id: 10,
        //   url: require("@/assets/Img/myImg/Binance.png"),
        //   t: `user.tools.ba`,
        //   flag: true,
        //   callBack: () => {
        //     // this.$router.push('InviteFriends') // 邀请好友跳转-小饶
        //     this.$router.push("ba");
        //   },
        // },
        // {
        //   id: 7,
        //   url: require('@/assets/icon/myself/service.png'),
        //   t: `My.service`,
        //   callBack: () => {
        // this.$router.push('Announcement') // 系统公告-小饶
        // this.$api.toService()
        // },
        // },
        // {
        //   id: 8,
        //   url: require('@/assets/Img/myImg/Teamreport.png'),
        //   t: `My.team`,
        //   callBack: () => {
        //     this.$router.push('TheTeamReport') // 系统公告-小饶
        //   },
        // },
        /**
         * 语言修改
         */
        // {
        //   id: 9,
        //   url: require("@/assets/Img/myImg/language.png"),
        //   t: `My.language`,
        //   icon: "icon-duoyuyan1",
        //   flag: true,
        //   callBack: () => {
        //     this.show = true;
        //   },
        // },
      ],
      show: false,
      actions: [
        {
          name: this.$t("My.languageButton.english"),
          type: "en",
        },
        {
          name: "中文",
          type: "zh",
        },
        // {
        //   name: "ข้อความ",
        //   type: "th",
        // },
        // {
        //   name: "Türk dili",
        //   type: "tr",
        // },
        // {
        //   name: this.$t('My.languageButton.indonesian'),
        //   type: 'idn',
        // },
        {
          disabled: true,
        },
      ],
      /**
       * new
       */
      levelList: [
        this.$t("Cart.grade.SliverMember"),
        this.$t("Cart.grade.GoldMember"),
        this.$t("Cart.grade.PlatinumMember"),
        this.$t("Cart.grade.DiamondMember"),
      ],
      levelText: "",
    };
  },
  methods: {
    showSign() {
      // console.log(1, this.MemberInformation.sign);
      if (this.MemberInformation?.sign) {
        Dialog.alert({
          message: this.MemberInformation.sign,
          confirmButtonText: this.$t("My.Button.Two")
        })
      }
    },
    handleClick(row) {
      if (!!row.callBack) {
        row.callBack();
      }
    },
    //会员说明
    explain() {
      this.$router.push({ name: "levelUpgrade" });
    },
    handleLinkBill() {
      this.$router.push("bill-record"); // 个人中心跳转-小饶
    },
    onSelect(e) {
      // console.log(e.type);
      // this.country = e.type;
      // switch (e.type) {
      //   case "en":
      //     this.$i18n.locale = e.type;
      //     Locale.use("en-US", enUS);
      //     Cookies.set("language", e.type);
      //     localStorage.setItem("language", e.type);
      //     break;
      //   case "idn":
      //     this.$i18n.locale = e.type;
      //     Locale.use("en-US", enUS);
      //     Cookies.set("language", e.type);
      //     localStorage.setItem("language", e.type);
      //     break;
      //   case "zh":
      //     this.$i18n.locale = e.type;
      //     Locale.use("zh-CN", zhCN);
      //     Cookies.set("language", e.type);
      //     localStorage.setItem("language", e.type);
      //     break;
      //   case "th":
      //     this.$i18n.locale = e.type;
      //     Locale.use("th-TH", thTH);
      //     Cookies.set("language", e.type);
      //     localStorage.setItem("language", e.type);
      //     break;
      //   case "tr":
      //     this.$i18n.locale = e.type;
      //     Locale.use("tr-TR", trTR);
      //     Cookies.set("language", e.type);
      //     localStorage.setItem("language", e.type);
      //     break;
      // }
      this.$i18n.Locale = e.value;
      this.changeLanguage(e.value)
      Cookies.set('lang',e.value)
      this.language = e.value;
      this.show = false;
      // location.reload();
      /**
       * new
       */
      // this.levelList = [
      //   this.$t("Cart.grade.SliverMember"),
      //   this.$t("Cart.grade.GoldMember"),
      //   this.$t("Cart.grade.PlatinumMember"),
      //   this.$t("Cart.grade.DiamondMember"),
      // ];
      // let level =
      //   JSON.parse(localStorage.getItem("user-info"))[1].data.player.level || 1;
      // this.levelList.forEach((_, i) => {
      //   if (level === i + 1) {
      //     this.levelText = _;
      //   }
      // });
    },
    // 顶部导航栏的设置事件-小饶
    Personal() {
      if (this.$cookie.get("userInfo")) {
        this.$router.push("PersonalCenter");
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
      }
    },
    onClose() {
      this.show = false;
    },
    // 点击充值的按钮
    RechargeEvent() {
      Dialog.alert({
        title: this.$t('My.MemberInformation.Recharge'),
        message: this.$t('hint.lxkf'),
        showCancelButton: true,
        confirmButtonText: this.$t('Login.goLoginText'),
        cancelButtonText: this.$t('newAdd.Cancel')
      }).then(() => {
        this.$api.toService();
      }).catch(() => {
      });
      // this.$router.push("/new-recharge");
    },
    // 点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // 注销的按钮
    logoutEvent() {
      let _this = this;
      if (_this.MemberInformation.token) {
        Dialog.confirm({
          // title: this.$t("My.quit"),
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        })
          .then(() => {
            let pwdState = _this.$cookie.get("the-pwd-state");
            let pwdObj = _this.$cookie.get("the-pwd-obj");
            _this.$cookie.clear();
            _this.$cookie.set("the-pwd-state", pwdState);
            _this.$cookie.set("the-pwd-obj", pwdObj);
            _this.$router.push("/login");
            // window.location.reload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取账户余额
    BalanceEvent() {
      let form = {
        cmd: "202445",
        uid: this.module.UIDEvent(),
      };
      REQUEST_API(form).then((res) => {
        this.$store.commit("SET_MONEY_INFO", res.data.notifyList[0].data);
        // this.money = res.data.notifyList[0].data.diamond
        this.money = deciTurnMoney(this.$store.state.user.moneyObj.diamond);
      });
    },
    // 获取用户信息
    getUserInfo() {
      // if (this.$cookie.get('userInfo')) {
      //   this.MemberInformation = JSON.parse(this.$cookie.get('userInfo'))
      // }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.MemberInformation = { ...res, username: maskMiddle(res.username) };
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    getUserInfo2() {
      GET_USER_INFP()
        .then((r) => {
          if (r.data.ret === 1) {
            this.$cookie.set("userInfo", JSON.stringify(r.data.data));
            this.$cookie.set("token", r.data.data.token);
            this.MemberInformation = r.data.data;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getDownloadUrl() {
      let url = {};
      let down = {
        id: 5,
        url: require("@/assets/icon/myself/download.png"),
        t: `My.download`,
        callBack: () => {
          window.location.href = url.android;
        },
      };
      this.$api.getNotice((res) => {
        if (res.appurl_android) {
          url.android = res.appurl_android;
          // this.gridItemArr.push(down);
        }
      });
    },
  },
  created() {
    this.getUserInfo();
    this.getDownloadUrl();
    // this.getUserInfo2()
    // this.BalanceEvent() // 获取账户余额
    // if (this.module.PersonalEvnet()[1].data.player.icon) {
    //   this.MemberInformation.url =
    //     this.module.PersonalEvnet()[1].data.player.icon
    // } else {
    //   this.MemberInformation.url = require('@/assets/Img/myImg/head.png')
    // }
    // this.MemberInformation.name =
    //   this.module.PersonalEvnet()[1].data.player.nickname
    // this.MemberInformation.code = this.module.PersonalEvnet()[0].data.info.id
    // this.MemberInformation.money = this.module.PersonalEvnet()[0].data.info.gold
    /**
     * new
     */
    // let level =
    //   JSON.parse(localStorage.getItem('user-info'))[1].data.player.level || 1
    // this.levelList.forEach((_, i) => {
    //   if (level === i + 1) {
    //     this.levelText = _
    //   }
    // })
  },
};
</script>

<style lang="less">
// @Color: #e23d6d;
@Color: var(--theme);
@button-border-radius: 20px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

#user {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--hallBg);

  // background-color: rgb(245, 245, 245);
  .userInfo_bar_wrap {
    overflow: hidden;
    background-size: 100% 100%;
    background-color: var(--bg);
  }

  .spancolor {
    color: @Color;
  }

  // 顶部
  .user_title {
    // padding: 5.333vw 4vw;

    height: 26.667vw;
    overflow: hidden;
    color: var(--gold);
    // background: url(../../assets/Img/myImg/bg_01.6e175c41.png) no-repeat top;
    background: var(--navTheme);
  }

  .user_titleContent {
    .flex(space-between);
    color: var(--gold);
    margin: @wrapPadding;

    .user_contentLeft {
      .flex(start);

      .contentLeft_text {
        margin-left: 10px;

        >div {
          font-size: calc(15rem / 16);
          margin-top: calc(3rem / 16);

          &:nth-child(1) {
            margin-top: 0;
          }

          .label {
            // color: #8ba6c1;
            color: var(--gold);
          }
        }

        .contentLeft_Vip {
          // background: rgba(255, 255, 255, 0.2);
          // text-align: center;
          // border-radius: 20px;
        }
      }
    }

    .user_contentRight img {
      width: 7vw;
      height: 7vw;
      // font-size: 5.867vw;
    }
  }

  // 中间
  .user_content {
    // margin: @wrapPadding;
    // margin: @wrapPadding 10vw 0;
    // padding: calc(@wrapPadding * 2) calc(@wrapPadding * 1.5)
    //   calc(@wrapPadding * 1.3);
    margin-top: calc(30rem / 16);
    padding: calc(@wrapPadding * 1.5) 14vw calc(@wrapPadding * 2);
    // background: @comBG;
    // background-image: url("../../assets/mine/infoBG.png");
    background-size: 100% 100%;
    // border-radius: @comRadius;
    border-top-left-radius: @comRadius;
    border-top-right-radius: @comRadius;
    background-repeat: no-repeat;

    .balance_bar {
      color: var(--gray3);
      .flex(space-between);

      .label {
        font-size: calc(15rem / 16);
      }

      .value {
        color: var(--gold);
        font-size: calc(18rem / 16);
      }
    }

    .trade_btns {
      margin-top: calc(20rem / 16);
      color: var(--gold);
      .flex(space-between);

      >div {
        font-size: calc(12rem / 16);
        display: flex;
        justify-content: center;
        align-items: center;
        @padding: calc(10rem / 16);
        min-width: calc(40% - @padding);
        max-width: calc(50% - @padding);
        border-radius: 10px;
        padding: @padding;
        color: var(--light);

        &:active {
          opacity: 0.8;
        }

        &:nth-child(1) {
          // background: linear-gradient(#16a1ba, #098fa9);
          background: linear-gradient(90deg, #62aaff, #b8daff);
        }

        &:nth-child(2) {
          // background: linear-gradient(#e3bf77, #d09534);
          // background: linear-gradient(90deg, #ce8b71, #ffd2b7);
          background: linear-gradient(90deg, #62aaff, #b8daff);
        }

        >img {
          height: calc(23rem / 16);
          margin-right: calc(12rem / 16);
        }
      }
    }

    .balance_wrap {
      padding: 5vw;

      .balance_text {
        color: #b8ecff;
        margin-bottom: 5vw;
      }

      .contentCase_btns {
        .flex(space-around);

        .contentCase_btn {
          padding: 1.867vw 2vw;
          font-size: 3.733vw;
          line-height: normal;
          color: var(--textColor);
          text-align: center;
          border-radius: 7.467vw;
          background-color: @Color;
          // background: @comBG;
        }
      }

      .moey_num {
        color: @Color;
        font-size: 6.4vw;
        font-weight: 400;
      }
    }
  }

  .group {
    // margin-top: calc(12rem / 16);
    // margin-bottom: calc(20rem / 16);
    margin: 6px @wrapPadding;
    border-radius: @comRadius;
    // background: var(--bg);
    @bg: #f5f5f5;
    background: var(--gameBg);
    overflow: hidden;

    // &:nth-of-type(2),
    // &:nth-of-type(3) // &:nth-of-type(4),

    // // &:nth-of-type(5),
    // // &:nth-of-type(6) 
    //   {
    //   .cell_item {
    //     background: linear-gradient(90deg, #62aaff, #b8daff);
    //   }
    // }

    // &:nth-of-type(7),
    // &:nth-of-type(8),
    // &:nth-of-type(9),
    // &:nth-of-type(10),
    // &:nth-of-type(11) {
    //   .cell_item {
    //     background: linear-gradient(90deg, #e3bf77, #d09534);
    //   }
    // }

    &::after {
      border: none;
    }

    .cell_item {
      // border-bottom: 1px solid #b8ecff;
      // background: @comBG;
      // background: @bg;
      // background: linear-gradient(90deg, #ce8b71, #ffd2b7);
      background: var(--moduleColor);

      .van-cell__right-icon {
        color: var(--gold);
      }

      &::after {
        // border: none;
        // border-color: #124b55;
        border-color: var(--borderColor);
      }

      .left_wrap {
        display: flex;
        align-items: center;
        // color: var(--textColor);
        color: var(--gold);

        .iconfont {
          font-size: calc(26rem / 16);
          margin-right: calc(12rem / 16);
          // color: var(--theme);
          // color: var(--theme1);
          color: var(--gold);
        }
      }
    }
  }

  // 第二个板块
  .caseT {
    margin-top: 5vw;
  }

  .logout {
    margin: @wrapPadding;
    margin-top: calc(35rem / 16);
    border-radius: 6px;
    overflow: hidden;
  }

  .van-grid-item__content {
    justify-content: start;
  }
}

.user_img {
  width: 15vw;
  border-radius: 10vw;
}
</style>